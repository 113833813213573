<h2 class="section-subtitle">Coinage and Valuables</h2>
<div id="purse" class="container">
  <mat-card appearance="outlined">
    <mat-card-content>
    <mat-list dense>
      <h2>Coins</h2>
      <mat-icon color="primary" class="purse-info-icon" matTooltip="Hold shift to change by 10, Ctrl to change by 100, and both to change by 1000">info</mat-icon>
      <mat-list-item><span>Platinum:&nbsp; </span>
        {{character.purse.platinum | number}}
        <div class="mat-list-actions">
          <button [disabled]="character.purse.platinum < 1" mat-mini-fab color="warn" (click)="changeCoin('platinum',-1, $event)">
            <mat-icon>remove</mat-icon>
          </button>
          <button mat-mini-fab color="primary" (click)="changeCoin('platinum',1, $event)">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item><span>Gold:&nbsp; </span> {{character.purse.gold | number}}
        <div class="mat-list-actions">
          <button mat-mini-fab color="warn" (click)="changeCoin('gold',-1, $event)">
            <mat-icon>remove</mat-icon>
          </button>
          <button mat-mini-fab color="primary" (click)="changeCoin('gold',1, $event)">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item><span>Silver:&nbsp; </span> {{character.purse.silver | number}}
        <div class="mat-list-actions">
          <button mat-mini-fab color="warn" (click)="changeCoin('silver',-1, $event)">
            <mat-icon>remove</mat-icon>
          </button>
          <button mat-mini-fab color="primary" (click)="changeCoin('silver',1, $event)">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item><span>Copper:&nbsp; </span> {{character.purse.copper | number}}
        <div class="mat-list-actions">
          <button mat-mini-fab color="warn" (click)="changeCoin('copper',-1, $event)">
            <mat-icon>remove</mat-icon>
          </button>
          <button mat-mini-fab color="primary" (click)="changeCoin('copper',1, $event)">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-list-item><span>Total: </span> {{character.purse.getTotal()}}</mat-list-item>
      <mat-divider></mat-divider>
    </mat-list>
    </mat-card-content>
  </mat-card>
  <mat-card appearance="outlined">
    <mat-card-content>
    <mat-list>
      <h2>Gems & Valuables</h2>
      <item-list [items]="character.purse.gems"></item-list>
    </mat-list>
    </mat-card-content>
  </mat-card>
  <mat-card appearance="outlined">
    <mat-card-content>
    <mat-list>
      <h2>Magic Items</h2>
      <item-list [items]="character.magic_items">
      </item-list>
    </mat-list>
    </mat-card-content>
  </mat-card>
</div>
