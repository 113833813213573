<h2 mat-dialog-title>{{item.inventoryString()}}</h2>
<mat-dialog-content class="mat-typography">
  <mat-form-field>
    <mat-label>Name</mat-label>
    <input matInput [(ngModel)]="item.name"/>
  </mat-form-field>
  <br/>
  <mat-form-field>
    <mat-label>Weight</mat-label>
    <input matInput [(ngModel)]="item.weight" type="number"/>
  </mat-form-field>
  <br/>
  <mat-form-field>
    <mat-label>Quantity</mat-label>
    <input matInput [(ngModel)]="item.quantity" type="number"/>
  </mat-form-field>
  <br/>
  <mat-form-field>
    <mat-label>Description</mat-label>
    <textarea matInput [(ngModel)]="item.description" type="number"></textarea>
  </mat-form-field>
    <mat-dialog-actions align="end">
      <button mat-button *ngIf="this.list" [mat-dialog-close]="">Cancel</button>
      <button color="primary" mat-raised-button (click)="upsertItem()" [mat-dialog-close]="" cdkFocusInitial>Done</button>
    </mat-dialog-actions>
</mat-dialog-content>
