<h2 mat-dialog-title>Add Experience:</h2>
<mat-dialog-content class="mat-typography">
  <mat-form-field>
    <mat-label>Date</mat-label>
    <input matInput [matDatepicker]="picker" [(ngModel)]="experience.date">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  <mat-form-field>
    <mat-label> Points</mat-label>
  <input matInput [(ngModel)]="experience.points" type="number" />
  </mat-form-field>
  <br />
  <mat-form-field>
    <mat-label>Notes</mat-label>
    <textarea matInput [(ngModel)]="experience.notes"> </textarea>
  </mat-form-field>
  <br />
  <p *ngFor="let block of character.getExperience(); let i = index">
  {{block.class}} ({{character.getAbilityAbbreviation(block.prime)}}):&nbsp;{{ character.getBlockExperience(experience, i)}} +
    {{block.bonus_xp}}% Bonus = {{block.applyBonus(character.getBlockExperience(experience,i))}} of {{block.experienceNeededForNextLevel()}}
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button [mat-dialog-close]="" >Cancel</button>
  <button color="primary" mat-raised-button (click)="addExperience()" [mat-dialog-close]="" cdkFocusInitial>Done</button>
</mat-dialog-actions>
