<div class="container">
  <h1> Developer Edit Mode </h1>
  <p> While this project is under active development and some key features are missing from the proper UI this view will
    exist to let you tweak and update your character by editing the raw JSON</p>
  <strong>You can mess up your character and cause unusual behavior. Please save your character before making changes</strong>
  <textarea [(ngModel)]="raw"></textarea>
  <button mat-raised-button (click)="save()" color="warn"> Save </button>
</div>
<div class="changelog container">
  <h1>Change Log</h1>
  <dl>
    <dt><strong>Version 1.200</strong> - 09/08/2024</dt>
    <dd>Upgraded from Angular 9 to Angular 18</dd>
    <dt><strong>Version 1.105</strong> - 07/29/2024</dt>
    <dd>Allowed the importing of characters as hirelings</dd>
    <dt><strong>Version 1.104</strong> - 4/3/2022</dt>
    <dd>AddedCleric Deity Section</dd>
    <dd>Added Turning events</dd>
    <dd>Added Prayer and turning slot tracking</dd>
    <dt><strong>Version 1.103</strong> - 4/3/2022</dt>
    <dd>Cleric Spell overhaul including all included spells and descriptions</dd>
    <dt><strong>Version 1.102</strong> - 4/3/2022</dt>
    <dd>Bumping dependency versions</dd>
    <dd>Updating System Shock, Precision Thrown Distance, and other items to consider prime requisite bonuses</dd>
    <dt><strong>Version 1.101</strong> - 10/8/2020</dt>
    <dd>Added AC to the HUD</dd>
    <dd>Large Multiclassing Feature enabled</dd>
    <dt><strong>Version 1.100</strong> - 9/5/2020</dt>
    <dd>Large Multiclassing Feature enabled</dd>
    <dd>A few copy updates</dd>
    <dd>Enabled selection of any ability as prime requisite</dd>
    <dd>Created changelog</dd>
    <dd>Arbitrarily selected 1.100 because I'm lazy and didn't version things from the onset</dd>
    <dd>Added version to header</dd>
  </dl>
</div>
