<div *ngIf="character.getTotalPrayerSlots() > 0" class="container" id="cleric">
  <mat-card appearance="outlined">
    <mat-card-content>
      <mat-icon *ngIf="editing!='deity'" color='primary' (click)="editSection('deity')" class="edit">edit</mat-icon>
      <mat-list *ngIf="editing!='deity'">
        <h2>Deity & Prayers</h2>
        <mat-list-item><span>Deity name: &nbsp;</span> {{ character.deity_name }}</mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item><span>Deity Domain: &nbsp;</span> {{ character.deity_domain }}</mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item><span>Divine Edict: &nbsp;</span> {{ character.deity_edict }}</mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item><span>Divine Anathema: &nbsp;</span> {{ character.deity_anathema }}</mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item>
          <span>Prayer Slots: &nbsp;</span> {{ character.getTotalPrayerSlots() - character.used_pray_slots }}
          / {{ character.getTotalPrayerSlots() }}
          <div class="mat-list-actions">
            <button [disabled]="character.used_pray_slots >= character.getTotalPrayerSlots()" mat-mini-fab color="warn"
                    (click)="changePrayer(1)">
              <mat-icon>remove</mat-icon>
            </button>
            <button mat-mini-fab color="primary" (click)="changePrayer(-1)"
                    [disabled]="character.used_pray_slots <= 0">
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </mat-list-item>
        <mat-list-item>
          <span>Turnings Slots: &nbsp;</span> {{ character.getTotalTurningSlots() - character.used_turning_slots }}
          / {{ character.getTotalTurningSlots() }}
          <div class="mat-list-actions">
            <button [disabled]="character.used_turning_slots >= character.getTotalTurningSlots()" mat-mini-fab
                    color="warn"
                    (click)="changeTurning(1)">
              <mat-icon>remove</mat-icon>
            </button>
            <button mat-mini-fab color="primary" (click)="changeTurning(-1)"
                    [disabled]="character.used_turning_slots <= 0">
              <mat-icon>add</mat-icon>
            </button>
          </div>
        </mat-list-item>
        <mat-divider></mat-divider>
      </mat-list>
      <div *ngIf="editing=='deity'">
        <h2>Edit Deity</h2>
        <mat-list>
          <mat-list-item>
            <mat-form-field>
              <mat-label>Deity Name:</mat-label>
              <input matInput [(ngModel)]="character.deity_name"/>
            </mat-form-field>
          </mat-list-item>
          <mat-list-item>
            <mat-form-field>
              <mat-label>Deity Domain:</mat-label>
              <input matInput [(ngModel)]="character.deity_domain" type="text"/>
            </mat-form-field>
          </mat-list-item>
          <mat-list-item>
            <mat-form-field>
              <mat-label>Divine Edict:</mat-label>
              <input matInput [(ngModel)]="character.deity_edict"/>
            </mat-form-field>
          </mat-list-item>
          <mat-list-item>
            <mat-form-field>
              <mat-label>Divine Anathema:</mat-label>
              <input matInput [(ngModel)]="character.deity_anathema"/>
            </mat-form-field>
          </mat-list-item>
          <mat-list-item>
            <button mat-raised-button color="primary" (click)="doneEditing()">Done</button>
          </mat-list-item>
        </mat-list>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card appearance="outlined">
    <mat-card-content>
      <mat-icon color="primary" class="turning-info-icon"
                matTooltip="If a raw number roll 2d6 and if you meet or exceed the number listed, roll another 2d6 and turn that many hit die. T means automatically turn the number of d6, D means automatically destroy the number of d6">
        info
      </mat-icon>
      <h2>Turning Events</h2>
      <mat-list>
        <mat-list-item><span>Skeleton: &nbsp;</span>     {{ character.getTurningEvents().skeleton }}</mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item><span>Zombie: &nbsp;</span> {{ character.getTurningEvents().zombie }}</mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item><span>Ghoul: &nbsp;</span> {{ character.getTurningEvents().ghoul }}</mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item><span>Wight: &nbsp;</span> {{ character.getTurningEvents().wight }}</mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item><span>Wraith: &nbsp;</span>  {{ character.getTurningEvents().wraith }}</mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item><span>Mummy: &nbsp;</span>      {{ character.getTurningEvents().mummy }}</mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item><span>Spectre: &nbsp;</span>  {{ character.getTurningEvents().spectre }}</mat-list-item>
        <mat-divider></mat-divider>
        <mat-list-item><span>Vampire: &nbsp;</span>      {{ character.getTurningEvents().vampire }}</mat-list-item>
        <mat-divider></mat-divider>
      </mat-list>
    </mat-card-content>
  </mat-card>
</div>
