<div cdkDropListGroup>
  <character-overview [character]="character"></character-overview>
  <character-attributes [character]="character"></character-attributes>
  <character-cleric [character]="character"></character-cleric>
  <character-spell-book [character]="character"></character-spell-book>
  <character-experience [character]="character"></character-experience>
  <character-purse [character]="character"></character-purse>
  <character-inventory [character]="character"></character-inventory>
  <character-mounts [character]="character"></character-mounts>
  <character-notes [character]="character"></character-notes>
</div>
