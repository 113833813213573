<h2 class="section-subtitle">Experience</h2>
<div class="add-experience">
  <button class="add-experience-button"mat-mini-fab color="primary" (click)="openDialog()"><mat-icon>add</mat-icon></button>
</div>
<div id="blocks-section" class="container">
  <div *ngFor="let block of character.getExperience(); let i = index">
    <h3>
      Level {{block.currentLevel()}} {{block.class}}
    <br />
      {{block.currentExperience() }} / {{block.totalExperienceForLevel(block.currentLevel() + 1)}}
    </h3>

    <mat-accordion>
      <mat-expansion-panel *ngFor="let experience of block.experiences; let j = index">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{experience.date | date:'longDate'}}
          </mat-panel-title>
          <mat-panel-description class="description">
            {{experience.notes | slice:0:75}}
          </mat-panel-description>
          <mat-panel-description>
            {{experience.points}}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-form-field>
          <mat-label>Date:</mat-label>
          <input matInput [matDatepicker]="picker_i_j" [(ngModel)]="experience.date">
          <mat-datepicker-toggle matSuffix [for]="picker_i_j"></mat-datepicker-toggle>
          <mat-datepicker #picker_i_j></mat-datepicker>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Points:</mat-label>
          <input matInput [(ngModel)]="experience.points" type="number">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Notes:</mat-label>
          <textarea matInput [(ngModel)]="experience.notes"> </textarea>
        </mat-form-field>
        <p class="delete-experience">
          <button (click)="deleteExperience(experience, block)" mat-mini-fab aria-label="Delete Experience" color="warn">
            <mat-icon>delete</mat-icon>
          </button>
        </p>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>


