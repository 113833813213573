<div id="sessions-section" class="container">
  <h2 class="section-subtitle">Sessions</h2>
  <div id="add-session">
    <button (click)="addSession()" id="add-session-button" mat-mini-fab aria-label="Add Session Button" color="primary">
      <mat-icon>add</mat-icon>
    </button>
  </div>
  <mat-accordion>
    <mat-expansion-panel *ngFor="let session of sessions; let i = index">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{session.date | date:'longDate'}}
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
        <mat-panel-description>
          {{session.game_date}}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-form-field>
        <mat-label>Session date:</mat-label>
        <input matInput [matDatepicker]="picker_i" [(ngModel)]="session.date">
        <mat-datepicker-toggle matSuffix [for]="picker_i"></mat-datepicker-toggle>
        <mat-datepicker #picker_i></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Game date:</mat-label>
        <input matInput [(ngModel)]="session.game_date">
      </mat-form-field>
      <textarea [(ngModel)]="session.notes">{{session.notes}}</textarea>
      <p class="delete-session">
        <button (click)="deleteSession(session)" mat-mini-fab aria-label="Delete Session" color="warn">
          <mat-icon>delete</mat-icon>
        </button>
      </p>
    </mat-expansion-panel>
  </mat-accordion>
</div>


